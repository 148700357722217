<template>
  <b-container fluid>
    <div v-if="project" class="my-3">
      <b-row>
        <b-col cols="12" lg="3">
          <h3 class="text-muted">{{ $t('projects.project-details') }}</h3>
        </b-col>
      </b-row>

      <b-row class="my-2 border-bottom">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('projects.table.heading.project-id') }}
        </b-col>
        <b-col cols="12" lg="9">{{ project.name }}</b-col>
      </b-row>
      <b-row class="my-2 border-bottom">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('projects.table.heading.project-manager') }}
        </b-col>
        <b-col v-if="!contactProfiles.includes('admin')" cols="12" lg="9">
          <span
            v-if="project.project_managers && !project.project_managers.length"
          >
            {{ $t('projects.no-pm-assigned') }}
          </span>
          <span
            v-else
            v-for="(project_manager, index) in project.project_managers"
            :key="project_manager.sfid"
          >
            {{
              `${project_manager.name}${
                index == project.project_managers.length - 1 ? '' : ', '
              }`
            }}
          </span>
        </b-col>
        <b-col v-else cols="12" lg="3" class="pb-2">
          <project-manager-dropdown
            :value="selectedProjectManager(project)"
            :projectId="project.id"
            :setProjectManager="setProjectManager"
            :projectManagers="projectManagers"
          />
        </b-col>
      </b-row>

      <b-row class="my-2 px4">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('projects.jobs') }}
        </b-col>
        <b-col cols="12" lg="12">
          <b-table
            v-if="project.jobs && project.jobs.length"
            center
            :items="project.jobs"
            :fields="jobFields"
            ref="jobsTable"
          >
            <template #cell(_brand_ids)="{ item }">
              {{ item.job.name }}

              <p v-if="shouldDisplayQuote(item)">
                <a href="#" @click.prevent="getQuotePath(item.job.sfid)">
                  {{ $t('view-quote') }}
                </a>
              </p>
            </template>
            <template #cell(job._price)="{ item }">
              &euro; {{ item.job.price_selling_sum }}
            </template>
            <template #cell(customer._fullname)="{ item }">
              {{ item.job.customer }}
            </template>
            <template #cell(job.created_date)="{ item }">
              {{ item.job.created_date | moment('L') }}
            </template>

            <template #cell(job.status)="{ item }">
              {{ statusesById[item.job.status] }}
            </template>

            <template #cell(_actions)="{ item, toggleDetails }">
              <b-button class="mr-2" size="sm" @click="toggleDetails">
                {{ $t('projects.details') }}
              </b-button>

              <project-documents-dropdown
                :buttonText="'Docs'"
                :item="item"
                :accessToken="accessToken"
              />
            </template>
            <template v-if="project.services.length" #row-details="{ item }">
              <job-details :item="item" @refresh="$refs.jobsTable.refresh()" />
            </template>
          </b-table>
          <span v-else>{{ $t('projects.no-jobs-assigned') }}</span>
        </b-col>
      </b-row>

      <b-row class="my-2 border-bottom">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('projects.table.heading.project-value') }}
        </b-col>
        <b-col cols="12" lg="9">
          {{
            project.price_selling_sum && `${project.price_selling_sum} &euro;`
          }}
        </b-col>
      </b-row>
      <b-row class="my-2 border-bottom">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('projects.table.heading.customer') }}
        </b-col>
        <b-col
          cols="12"
          lg="9"
          v-if="project.customers && project.customers.length"
        >
          <div
            v-for="(customer, index) in project.customers"
            :key="customer.id"
          >
            <div>
              {{
                `${customer.name}${
                  index == project.customers.length - 1 ? '' : ', '
                }`
              }}
            </div>
            <div>{{ getCustomerShippingAddress(customer) }}</div>
          </div>
        </b-col>
        <b-col cols="12" lg="9" v-else>N/A</b-col>
      </b-row>
      <b-row class="my-2 border-bottom">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('projects.table.heading.created-date') }}
        </b-col>
        <b-col cols="12" lg="9">
          {{
            project.jobs &&
            project.jobs[0] &&
            project.jobs[0].job.created_date | moment('L')
          }}
        </b-col>
      </b-row>

      <b-row class="my-2 border-bottom">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('projects.table.heading.documentation') }}
        </b-col>
        <b-col cols="12" lg="9">
          <p
            v-for="(value, propertyName) in project.documents"
            :key="propertyName"
          >
            <strong>{{ $t(`projects.image.${propertyName}`) }}:</strong>
            &nbsp;
            <project-documents-dropdown
              :documentType="propertyName"
              :buttonText="'Download'"
              :item="value"
              :accessToken="accessToken"
            />
          </p>
        </b-col>
      </b-row>
      <b-row v-if="showProjecNotifications" class="my-2 pt3 border-bottom">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('projects.table.heading.notifications') }}
        </b-col>
        <b-col cols="12" lg="9">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              v-model="project.notifications"
              class="custom-control-input"
              id="customSwitches"
              @change="switchChangeHandler"
            />
            <label
              class="custom-control-label gid-notifications"
              for="customSwitches"
            >
              {{ project.notifications ? 'On' : 'Off' }}
            </label>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-2 pt3 border-bottom pb-2">
        <b-col cols="12" lg="3" class="text-muted">
          {{ this.$t('projects.table.heading.history') }}
        </b-col>
        <b-col cols="12" lg="9">
          <b-button @click.stop="$refs.logView.showLog(project.id)">
            {{ $t('projects.view-history') }}
          </b-button>
          <log-list
            ref="logView"
            :statuses="statuses"
            :context="'brand-project'"
            :logs="this.project.logs"
          />
        </b-col>
      </b-row>
    </div>

    <div v-else-if="project == null">
      <h1>{{ $t('projects.project-not-found') }}</h1>
    </div>
  </b-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import LogList from '@gid/vue-common/components/LogList';
import JobDetails from './JobDetails.vue';
import ProjectManagerDropdown from '@/components/ProjectManagerDropdown.vue';
import ProjectDocumentsDropdown from '@/components/ProjectDocumentsDropdown.vue';
import { JobStatusEnum, ContactProfileEnum } from '@gid/models';

export default {
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  components: {
    'log-list': LogList,
    'job-details': JobDetails,
    'project-manager-dropdown': ProjectManagerDropdown,
    'project-documents-dropdown': ProjectDocumentsDropdown,
  },
  computed: {
    ...mapGetters(['contactProfiles', 'locale', 'access_token']),
    accessToken() {
      return this.access_token;
    },
    getCustomerShippingAddress() {
      return (customer) => {
        return `${customer?.shipping_address?.postal_code} ${customer?.shipping_address?.city}`;
      };
    },
    selectedProjectManager() {
      return (item) => {
        if (item && item.project_managers && item.project_managers.length) {
          return item.project_managers.map((entry) => ({
            value: entry.sfid,
            text: `${entry.first_name} ${entry.last_name}`,
          }))[0];
        }
        return { value: '', text: '' };
      };
    },
    statusesById() {
      return this.statuses.reduce((statuses, status) => {
        statuses[status.value] = status.text;
        return statuses;
      }, {});
    },
    shouldDisplayQuote() {
      return (item) => {
        const statusesArray = [
          JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
          JobStatusEnum.ACCEPTED,
          JobStatusEnum.ON_HOLD,
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.FINISHED,
          JobStatusEnum.OPEN,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.COMPLETED,
        ];
        if (
          statusesArray.includes(item.job.status) &&
          this.project.opportunity.brand_can_view_quote
        ) {
          return true;
        }
        return false;
      };
    },
    showProjectNotifications() {
      return this.contactProfiles.some((cp) =>
        [
          ContactProfileEnum.PROJECT_MANAGER_DEFAULT,
          ContactProfileEnum.PROJECT_MANAGER_GENERIC,
        ].includes(cp),
      );
    },
  },
  async created() {
    await this.fetchProject();
    if (this.project) {
      this.getNotificationsForProject();
    }

    if (this.contactProfiles.includes('admin')) {
      this.fetchProjectManagers();
    }
    axios.get(`/api/brand/orders/statuses`).then((response) => {
      this.statuses = response.data.map((status) => ({
        text: status.brand_name[this.locale],
        value: status.job_status,
      }));
    });
  },
  data() {
    return {
      project: {},
      selectedPM: {},
      statuses: [],
      projectManagers: [],
      historyFields: [
        {
          label: this.$t('projects.history.table.heading.time-stamp'),
          key: 'timestamp',
        },
        {
          label: this.$t('projects.history.table.heading.user'),
          key: 'user',
        },
        {
          label: this.$t('projects.history.table.heading.change'),
          key: 'change',
        },
      ],
      jobFields: [
        { label: this.$t('jobs.table.heading.id'), key: '_brand_ids' },
        {
          label: this.$t('jobs.table.heading.order-value'),
          key: 'job._price',
        },
        {
          label: this.$t('jobs.table.heading.customer'),
          key: 'customer._fullname',
        },
        {
          label: this.$t('jobs.table.heading.created-date'),
          key: 'job.created_date',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        {
          label: this.$t('jobs.table.heading.appointment'),
          key: 'job',
          formatter: (value) =>
            value.appointment_start_timestamp ? this.getTimeRange(value) : '-',
        },
        { label: this.$t('jobs.table.heading.status'), key: 'job.status' },
        {
          label: this.$t('jobs.table.heading.documentation'),
          key: '_actions',
          tdClass: 'text-nowrap',
        },
      ],
    };
  },
  methods: {
    async fetchProject() {
      try {
        const response = await axios.get(
          `/api/brand/projects/${this.projectId}`,
        );

        this.project = response.data;
      } catch (e) {
        throw new Error(e);
      }
    },
    async fetchProjectManagers() {
      const response = await axios.get(`/api/brand/project-managers`);
      this.projectManagers = response.data.map((entry) => {
        return {
          value: entry.sfid,
          text: `${entry.first_name} ${entry.last_name}`,
        };
      });
    },
    async setProjectManager(projectId, pmId) {
      try {
        await axios.put(`/api/brand/projects/set-project-owner`, {
          project_id: `${projectId}`,
          contact_sfid: pmId,
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    getTimeRange(job) {
      return (
        this.$moment(job.appointment_start_timestamp).format('L H:mm') +
        ' - ' +
        this.$moment(job.appointment_end_timestamp).format('H:mm')
      );
    },
    getDocumentUrl(file) {
      let urls = [];
      for (let entry in file) {
        urls = file[entry].map(
          (url) => `/api/media/file/${url}?jwt=${this.accessToken}`,
        );
      }
      return urls;
    },
    jobHasDocs(job_view) {
      return Object.keys(job_view.files).length > 0;
    },
    statusesGroupedByName() {
      const byNames = this.statuses.reduce((statuses, status) => {
        if (!statuses[status.text]) statuses[status.text] = [];
        statuses[status.text].push(status.value);
        return statuses;
      }, {});
      return Object.keys(byNames).map((name) => ({
        text: name,
        value: byNames[name].join(','),
      }));
    },
    async switchChangeHandler() {
      try {
        await axios.put(`/api/brand/notifications/projects/${this.projectId}`, {
          notifies: this.project.notifications,
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async getNotificationsForProject() {
      try {
        const response = await axios.get(
          `/api/brand/notifications/projects/${this.projectId}`,
        );

        this.project.notifications = response.data.notifies;
      } catch (e) {
        throw new Error(e);
      }
    },
    async getQuotePath(job_id) {
      const resp = await axios.get(`/api/brand/orders/${job_id}/quote`);
      const path = `/api/media/file/${resp.data}?jwt=${this.access_token}`;
      window.open(path, '_blank').focus();
    },
  },
};
</script>
<style scoped>
.gid-notifications {
  cursor: pointer;
}
</style>
