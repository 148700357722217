var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.jobHasDocs(_vm.item))?_c('b-dropdown',{staticClass:"mr-1",attrs:{"right":"","size":"sm","text":_vm.buttonText}},[(_vm.item.files.picture_before || _vm.documentType == 'picture_before')?_c('b-dropdown-item',{attrs:{"href":`/api/media/file/${
      !_vm.documentType
        ? _vm.item.files.picture_before.paths[0]
        : _vm.item.picture_before.files[0]
    }?jwt=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('jobs.image.start'))+" ")]):_vm._e(),(_vm.item.files.picture_after || _vm.documentType == 'picture_after')?_c('b-dropdown-item',{attrs:{"href":`/api/media/file/${
      _vm.item.files.picture_after.paths[0] || Object.values(_vm.item.files)[0]
    }?jwt=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('jobs.image.finish'))+" ")]):_vm._e(),(_vm.item.files.protocol || _vm.documentType == 'protocol')?_vm._l((!_vm.documentType
        ? _vm.item.files.protocol.paths
        : Object.values(_vm.item.files)[0]),function(path,index){return _c('b-dropdown-item',{key:`protocol-${index}`,attrs:{"href":`/api/media/file/${
        !_vm.documentType
          ? _vm.item.files.protocol.paths[0]
          : Object.values(_vm.item.files)[0]
      }?jwt=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('jobs.image.protocol', { index }))+" ")])}):_vm._e(),(_vm.item.files.planning_customer || _vm.documentType == 'planning_customer')?_vm._l((!_vm.documentType
        ? _vm.item.files.planning_customer.paths
        : Object.values(_vm.item.files)[0]),function(path,index){return _c('b-dropdown-item',{key:`customer-${index}`,attrs:{"href":`/api/media/file/${path}?jwt=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('jobs.image.planning_customer', { index }))+" ")])}):_vm._e(),(_vm.item.files.planning_partner || _vm.documentType == 'planning_partner')?_vm._l((!_vm.documentType
        ? _vm.item.files.planning_partner.paths
        : Object.values(_vm.item.files)[0]),function(path,index){return _c('b-dropdown-item',{key:`partner-${index}`,attrs:{"href":`/api/media/file/${path}?jwt=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('jobs.image.planning_partner', { index }))+" ")])}):_vm._e(),(
      _vm.item.files.cancellation_picture ||
      _vm.documentType == 'cancellation_picture'
    )?_vm._l((!_vm.documentType
        ? _vm.item.files.cancellation_picture.paths
        : Object.values(_vm.item.files)[0]),function(path,index){return _c('b-dropdown-item',{key:`cancellation-${index}`,attrs:{"href":`/api/media/file/${path}?jwt=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('job.image.cancellation_picture'))+" ")])}):_vm._e(),(
      _vm.item.files.cancellation_protocol ||
      _vm.documentType == 'cancellation_protocol'
    )?_vm._l((!_vm.documentType
        ? _vm.item.files.cancellation_protocol.paths
        : Object.values(_vm.item.files)[0]),function(path,index){return _c('b-dropdown-item',{key:`cancellation-protocol-${index}`,attrs:{"href":`/api/media/file/${path}?jwt=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('job.image.cancellation_protocol'))+" ")])}):_vm._e(),(
      _vm.item.files.cancellation_picture_before ||
      _vm.documentType == 'cancellation_picture_before'
    )?_vm._l((!_vm.documentType
        ? _vm.item.files.cancellation_picture_before
        : Object.values(_vm.item.files)[0]),function(path,index){return _c('b-dropdown-item',{key:`cancellation-picture-before-${index}`,attrs:{"href":`/api/media/file/${path}?jwt=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('job.image.cancellation_picture_before'))+" ")])}):_vm._e(),(
      _vm.item.files.cancellation_picture_after ||
      _vm.documentType == 'cancellation_picture_after'
    )?_vm._l((!_vm.documentType
        ? _vm.item.files.cancellation_picture_after
        : Object.values(_vm.item.files)[0]),function(path,index){return _c('b-dropdown-item',{key:`cancellation-picture-after-${index}`,attrs:{"href":`/api/media/file/${path}?jwt=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('job.image.cancellation_picture_after'))+" ")])}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }