<template>
  <b-dropdown
    right
    size="sm"
    :text="buttonText"
    class="mr-1"
    v-if="jobHasDocs(item)"
  >
    <b-dropdown-item
      v-if="item.files.picture_before || documentType == 'picture_before'"
      :href="`/api/media/file/${
        !documentType
          ? item.files.picture_before.paths[0]
          : item.picture_before.files[0]
      }?jwt=${accessToken}`"
      target="_blank"
    >
      {{ $t('jobs.image.start') }}
    </b-dropdown-item>
    <b-dropdown-item
      v-if="item.files.picture_after || documentType == 'picture_after'"
      :href="`/api/media/file/${
        item.files.picture_after.paths[0] || Object.values(item.files)[0]
      }?jwt=${accessToken}`"
      target="_blank"
    >
      {{ $t('jobs.image.finish') }}
    </b-dropdown-item>
    <template v-if="item.files.protocol || documentType == 'protocol'">
      <b-dropdown-item
        v-for="(path, index) in !documentType
          ? item.files.protocol.paths
          : Object.values(item.files)[0]"
        :key="`protocol-${index}`"
        :href="`/api/media/file/${
          !documentType
            ? item.files.protocol.paths[0]
            : Object.values(item.files)[0]
        }?jwt=${accessToken}`"
        target="_blank"
      >
        {{ $t('jobs.image.protocol', { index }) }}
      </b-dropdown-item>
    </template>
    <template
      v-if="item.files.planning_customer || documentType == 'planning_customer'"
    >
      <b-dropdown-item
        v-for="(path, index) in !documentType
          ? item.files.planning_customer.paths
          : Object.values(item.files)[0]"
        :key="`customer-${index}`"
        :href="`/api/media/file/${path}?jwt=${accessToken}`"
        target="_blank"
      >
        {{ $t('jobs.image.planning_customer', { index }) }}
      </b-dropdown-item>
    </template>
    <template
      v-if="item.files.planning_partner || documentType == 'planning_partner'"
    >
      <b-dropdown-item
        v-for="(path, index) in !documentType
          ? item.files.planning_partner.paths
          : Object.values(item.files)[0]"
        :key="`partner-${index}`"
        :href="`/api/media/file/${path}?jwt=${accessToken}`"
        target="_blank"
      >
        {{ $t('jobs.image.planning_partner', { index }) }}
      </b-dropdown-item>
    </template>

    <template
      v-if="
        item.files.cancellation_picture ||
        documentType == 'cancellation_picture'
      "
    >
      <b-dropdown-item
        v-for="(path, index) in !documentType
          ? item.files.cancellation_picture.paths
          : Object.values(item.files)[0]"
        :key="`cancellation-${index}`"
        :href="`/api/media/file/${path}?jwt=${accessToken}`"
        target="_blank"
      >
        {{ $t('job.image.cancellation_picture') }}
      </b-dropdown-item>
    </template>
    <template
      v-if="
        item.files.cancellation_protocol ||
        documentType == 'cancellation_protocol'
      "
    >
      <b-dropdown-item
        v-for="(path, index) in !documentType
          ? item.files.cancellation_protocol.paths
          : Object.values(item.files)[0]"
        :key="`cancellation-protocol-${index}`"
        :href="`/api/media/file/${path}?jwt=${accessToken}`"
        target="_blank"
      >
        {{ $t('job.image.cancellation_protocol') }}
      </b-dropdown-item>
    </template>
    <template
      v-if="
        item.files.cancellation_picture_before ||
        documentType == 'cancellation_picture_before'
      "
    >
      <b-dropdown-item
        v-for="(path, index) in !documentType
          ? item.files.cancellation_picture_before
          : Object.values(item.files)[0]"
        :key="`cancellation-picture-before-${index}`"
        :href="`/api/media/file/${path}?jwt=${accessToken}`"
        target="_blank"
      >
        {{ $t('job.image.cancellation_picture_before') }}
      </b-dropdown-item>
    </template>
    <template
      v-if="
        item.files.cancellation_picture_after ||
        documentType == 'cancellation_picture_after'
      "
    >
      <b-dropdown-item
        v-for="(path, index) in !documentType
          ? item.files.cancellation_picture_after
          : Object.values(item.files)[0]"
        :key="`cancellation-picture-after-${index}`"
        :href="`/api/media/file/${path}?jwt=${accessToken}`"
        target="_blank"
      >
        {{ $t('job.image.cancellation_picture_after') }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>
<script>
export default {
  props: {
    item: Object,
    accessToken: String,
    buttonText: String,
    documentType: String,
  },

  methods: {
    jobHasDocs(job_view) {
      return Object.keys(job_view.files).length > 0;
    },
  },
};
</script>
